import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBIcon,
} from "mdb-react-ui-kit";

import {
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";

// import { Collapse, initMDB } from "mdb-react-ui-kit";
// import { MDBCollapse } from "mdb-react-ui-kit";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CountdownTimer from "../components/CountdownTimer";
import { motion } from "framer-motion";

import recordingClass from "../assets/recordingClass.mp4";
import onlineMeeting from "../assets/onlineMeeting.mp4";
import recoording from "../assets/recoording.mp4";
import classIcon from "../assets/classIcon.mp4";
import project from "../assets/project.mp4";
import notes from "../assets/notes.mp4";

import SAMEER from "../assets/SAMEER.jpg";
import SUMIT from "../assets/SUMIT.jpg";
import BABITA from "../assets/BABITA.jpg";
import Murari from "../assets/Murari.jpg";
import Mansi from "../assets/Mansi.jpg";
import Accordion from "../components/Accordion";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenModalCSS = () => setOpen2(true);
  const onCloseModalCSS = () => setOpen2(false);
  const onOpenModalJS = () => setOpen3(true);
  const onCloseModalJS = () => setOpen3(false);
  const onOpenModalREACT = () => setOpen4(true);
  const onCloseModaLREACT = () => setOpen4(false);

  const teachers = [
    {
      name: "SAMEER VARSHNEY",
      subject: "FULL STACK DEVELOPER",
      image: "/static/media/SAMEER.6452e56be4c7bc5ddee4.jpg",
    },
    {
      name: "BABITA ARYA",
      subject: "FRONTEND/REACT-NATIVE DEVELOPER",
      image: "/static/media/BABITA.1c33c2935e8dd05df691.jpg",
    },
    {
      name: "SUMIT BERWAL",
      subject: "FULL STACK DEVELOPER",
      image: "/static/media/SUMIT.4ba6cef90e39afa6848c.jpg",
    },

    {
      name: "Mansi Roy",
      subject: "Freelencer",
      image: "/static/media/Mansi.a2432371fac54c5b3c50.jpg",
    },
    {
      name: "Krishna Murari Sharma",
      subject: "FULL STACK DEVELOPER",
      image: "/static/media/Murari.6256b7ee6a691cd03467.jpg",
    },
    // Add more teachers as needed
  ];

  const items = [
    {
      title: "When Does The Course Start and What Are Timing?",
      content:
        "The course Will Start From 1 September and Timing For Batch is 8:30 PM to 9:30 PM.",
    },
    {
      title: "What are prerequisites for the course?",
      content:
        "A laptop with internet connection , hunger for learning and ready for grind",
    },
    {
      title: "Will I Get The Recording?",
      content:
        "YES, You will get the recordings but for limited time period. But If you enroll in this course today before time run out, You will get access of 365 days Recordings.",
    },
    {
      title: "Will I be able to make money after the class?",
      content:
        "Definitely YES, if you will follow all the rules. But at the end it totally depends on you how are you Coding.",
    },
    {
      title: "Will I become expert after the class?",
      content:
        "Only professional experience can make you expert. If not expert, then at least you will know hell a lot about Frontend Development",
    },
    {
      title: "Will you cover Nodejs and Data Structures?",
      content:
        "This is an intensive frontend course, we will not covering Nodejs and Data Structure.",
    },
    {
      title: "I made the payment, but didn’t receive any confirmation email.",
      content:
        "Write to console2success@gmail.com and my awesome support team will solve your queries in the fastest time possible..",
    },
    {
      title: "Will this be live or pre-recorded?",
      content:
        "NO, It is not a Pre – Recorded Course. It is going to be a completely LIVE Session of 50+ Hours.",
    },
    {
      title: "How do I enroll?",
      content:
        "Simply visit our website, select the course, and follow the enrollment process. Payment options are available, and you’ll gain access upon process completion.",
    },
    {
      title: "Can I get a refund?",
      content:
        "No. We don’t provide refunds on any case. If you are not sure about the course, checkout the content of the course, read more about the trainer and take an informed decision. Incase you miss out on attending the live class, we will be providing you the recordings",
    },
    {
      title: "Is there a certificate upon completion?",
      content:
        "Yes, you will receive a certificate of completion once you finish the course and meet the requirements.",
    },
    {
      title: "Will there be any job guarantee after this course?",
      content:
        "NO, This is not a job guarantee program.(NO false promises) But we are sure if you learn and practice dedicatedly, you will be so good in Frontend Development that interview will be a piece of cake.",
    },
    {
      title: "I have a question, how do I contact you?",
      content:
        "No worries, just send an email or whatsapp to me personally at console2suucess@gmail.com or 9358213928 and me and my team will sort it out for you.",
    },
  ];

  const cardTexts = [
    "Unlock your Potential with our Frontend Development course and boost your income for a lifetime",
    "You will learn everything you need to become a successful developer. Seize this incredible opportunity to secure lifelong automatic income!.",
    "Master Frontend Development from A to Z in just 45-60 days with our Night Syllabus course and set yourself up for a lifetime of skillful Dev!.",
    "Take advantage of our exclusive, limited-time offer—valid only for first 15 Students!",
    "Join us for a dynamic, 30-day journey of live sessions, starting September 1st. Don't miss this interactive experience!",
    "Gain unparalleled insights from leading industry experts!",
  ];

  const testimonials = [
    {
      quote:
        "I recently completed the frontend development course, and it was fantastic! The course content was well-structured and covered everything!",
      author: "Atul Verma",
      role: "Student",
    },
    {
      quote:
        "This course provided an excellent introduction to frontend development with HTML, CSS, JavaScript, and React.js.",
      author: "Rajan Jaikar",
      role: "Student",
    },
    {
      quote:
        "The interactive exercises and projects were very practical and helped me gain hands-on experience. The instructors were knowledgeable and always ready to help..",
      author: "Akash Maheswari",
      role: "Student",
    },
    {
      quote:
        "The curriculum was well-organized and covered all the essential tools and technologies needed for frontend development. The HTML and CSS lessons were clear and easy to follow, and the JavaScript and React.js",
      author: "Radhika",
      role: "School Teacher",
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.setAttribute("data-payment_button_id", "pl_OcNvwqOaIpLy9P");
    script.async = true;
    document.getElementById("razorpay-containers").appendChild(script);
  }, []);

  useEffect(() => {
    // Check if the script is already added
    if (!window.isRazorpayScriptAdded) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute("data-payment_button_id", "pl_OauVa0iX5tbbTZ");
      script.async = true;
      document.getElementById("razorpay-container").appendChild(script);
      window.isRazorpayScriptAdded = true; // Set the flag to indicate script is added
    }
  }, []);

  return (
    <>
      <section className="firstSection">
        {/* <iframe
          className="responsiveIframe"
          src="https://www.youtube.com/embed/kalxXDRa5Uk?si=VpQVnMddkXcGrkcm"
          title="Sameer"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
        ></iframe> */}

        <iframe
          className="responsiveIframe"
          src="https://www.youtube.com/embed/stdieGD13Ts?si=_BT-5H5kAHwEEVjG"
          title="Console2Success"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </section>

      <section className="secondSection">
        <div className="card-container">
          {cardTexts.map((text, index) => (
            <div key={index} className="Data">
              <div className="card-body">{text}</div>
            </div>
          ))}
        </div>
      </section>

      <section className="thirdSection">
        <MDBContainer>
          {/* <h1 className='why'>WHY US? Design, Develop, Deliver: Your Path to Success</h1> */}
          <h1 className="why">
            Design, Develop, Deliver: Your Path to Success
          </h1>
        </MDBContainer>
        <MDBContainer className="py-5">
          <div className="main-timeline-4 text-white">
            <div className="timeline-4 left-4">
              <MDBCard className="gradient-custom">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="brain" size="2x" className="mb-3" />
                  <h4 className="source"> Source of New Income</h4>
                  {/* <p className="small text-white-50 mb-4">May 21</p> */}
                  <p>
                    You Will Master Frontend Development during this Course and
                    will able earn through a high package job or by Freelencing.
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #NewIncomeSource
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #SkillMakeYouPerfect
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 right-4">
              <MDBCard className="gradient-custom-4">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="camera" size="2x" className="mb-3" />
                  <h4>Gain unparalleled expertise in frontend development</h4>
                  <p>
                    Gain unparalleled expertise in frontend development,
                    unlocking the potential to create captivating,
                    high-performance websites that set you apart from the
                    competition..
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #Expertise
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #Potential
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 left-4">
              <MDBCard className="gradient-custom">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="campground" size="2x" className="mb-3" />
                  <h4>LIVE Frontend Development Q&A</h4>
                  <p>
                    Engage in real-time Q&A sessions to resolve your queries and
                    enhance your frontend development skills with expert
                    guidance..
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #LiveFrontendQnA
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #RealTimeSupport
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 right-4">
              <MDBCard className="gradient-custom-4">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="sun" size="2x" className="mb-3" />
                  <h4>Full-Time Career in Frontend Development</h4>
                  {/* <p className="small text-white-50 mb-4">Apr 26</p> */}
                  <p>
                    Transform your passion for frontend development into a
                    full-time career, creating visually stunning and highly
                    functional websites that make an impact mastering the skills
                    needed to design and develop cutting-edge web applications.
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #CareerInTech
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #FrontendSuccess
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 left-4">
              <MDBCard className="gradient-custom">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="palette" size="2x" className="mb-3" />
                  <h4>Join the Elite group of Frontend Developers</h4>
                  {/* <p className="small text-white-50 mb-4">Apr 12</p> */}
                  <p>
                    Become a part of the top frontend developers, mastering
                    advanced techniques and creating exceptional web
                    experiences. Elevate your skills and stand out in the
                    competitive world of web development..
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #EliteFrontend
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #AdvancedWebDev
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 right-4">
              <MDBCard className="gradient-custom-4">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="laugh-beam" size="2x" className="mb-3" />
                  <h4>Expert in Frontend Development</h4>
                  {/* <p className="small text-white-50 mb-4">Apr 11</p> */}
                  <p>
                    Achieve expertise in frontend development, mastering the art
                    of creating visually stunning and highly functional
                    websites. Stay ahead in the competitive tech industry with
                    cutting-edge skills and knowledge. For that you have to work
                    hard
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #WebDevMastery
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #TechExcellence
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className="timeline-4 left-4">
              <MDBCard className="gradient-custom">
                <MDBCardBody className="p-4">
                  <MDBIcon fas icon="pizza-slice" size="2x" className="mb-3" />
                  <h4>Freelance Frontend Development</h4>
                  {/* <p className="small text-white-50 mb-4">Apr 5</p> */}
                  <p>
                    Unlock the potential of freelance frontend development,
                    offering your skills to create stunning websites for clients
                    worldwide. Enjoy the flexibility and freedom to work on
                    diverse projects while building a successful freelance
                    career..
                  </p>
                  <MDBBadge className="text-black mb-0 me-1" color="light">
                    #FreelanceCareer
                  </MDBBadge>
                  <MDBBadge className="text-black mb-0" color="light">
                    #FrontendProjects
                  </MDBBadge>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </MDBContainer>
      </section>

      <section className="fourthSection">
        <MDBContainer>
          <h1 className="why">Benefits for joining our Batch</h1>
        </MDBContainer>

        <div className="cardsData">
          <MDBCard className="cardss">
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={recoording} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>LIVE SESSION ON ZOOM</MDBCardTitle>
              <MDBCardText>
                All our expertly designed modules will be delivered via Zoom,
                ensuring an immersive and interactive learning experience. Learn
                every topic from a live trainer, and get your queries resolved
                in dedicated doubt-clearing sessions.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="cardss">
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={onlineMeeting} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>
                Announcing Our New Batch Starting on September 1!
              </MDBCardTitle>
              <MDBCardText>
                Exclusive Offer for Today Only! Scroll to the bottom of the page
                to discover our special deal. Don’t miss out on this
                limited-time opportunity.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="cardss">
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={classIcon} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>Class Schedule</MDBCardTitle>
              <MDBCardText>
                You are welcome to join the live class sessions, Monday to
                Friday: 8:30 PM - 9:30 PM (or 45-minute lecture). Also having
                dedicated doubt session , If you have any questions or require
                further assistance Contact us.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>

        <div className="cardsData">
          <MDBCard style={{ height: "450px", width: "300px" }}>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={recordingClass} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>Explore Class Recordings</MDBCardTitle>
              <MDBCardText>
                Seamless Access to Daily Live Class Recordings: Enjoy instant
                access upon each session's conclusion, with recordings valid for
                365 days.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard style={{ height: "450px", width: "300px" }}>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={notes} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardText>
                Personalized Assignments & Notes tailored to enhance your
                learning experience with comprehensive study materials.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard style={{ height: "450px", width: "300px" }}>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image hover-overlay"
            >
              <video width="100%" autoPlay muted loop>
                <source src={project} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>Project Excellence</MDBCardTitle>
              <MDBCardText>
                Achieving seamless user experiences through meticulous design
                and efficient implementation, adhering to best practices in
                frontend development.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="copyright-data">
          Source -
          <a
            href="https://www.vecteezy.com/free-videos/text"
            className="copyright-data"
          >
            Text Stock Videos by Vecteezy
          </a>
        </div>
      </section>

      <section className="fifthSection">
        <div className="grid">
          <div className="g-col-12 heading-container">
            Topics Covered in the Frontend Development Course
          </div>
          <div className="g-col-12 subheading-container">
            Our frontend development course is meticulously designed to guide
            you step-by-step, ensuring you become a successful developer in just
            60 days. Learn the latest techniques, from responsive design to
            advanced JavaScript frameworks, and master the skills necessary to
            create dynamic, high-performance web applications.
          </div>
          <div className="grids">
            <div className="grid-items">
              Explore HTML Topics:
              <button className="customButton" onClick={onOpenModal}>
                Click Here
              </button>
            </div>
            <div className="grid-items">
              Explore CSS Topics:{" "}
              <button className="customButton" onClick={onOpenModalCSS}>
                Click Here
              </button>
            </div>
            <div className="grid-items">
              Explore JS Topics:{" "}
              <button className="customButton" onClick={onOpenModalJS}>
                Click Here
              </button>
            </div>
            <div className="grid-items">
              Explore REACT Topics:{" "}
              <button className="customButton" onClick={onOpenModalREACT}>
                Click Here
              </button>
            </div>
          </div>
        </div>

        <div>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{ modal: "customModal" }}
          >
            <div className="modalHeader">
              <h2>HTML Topics</h2>
            </div>
            <div className="modalContent">
              <ul className="topicList">
                <li>HTML INTRODUCTIONS</li>
                <li>HTML COMMENTS</li>
                <li>HTML ELEMENTS</li>
                <li>HTML ATTRIBUTES</li>
                <li>HTML HEADINGS</li>
                <li>HTML PARAGRAPHS</li>
                <li>HTML TEXT FORMATTING</li>
                <li>HTML QUOTATIONS</li>
                <li>HTML COLORS</li>
                <li>HTML HYPERLINKS</li>
                <li>HTML FAVICONS</li>
                <li>HTML TABLES</li>
                <li>HTML LISTS</li>
                <li>HTML BLOCK AND INLINE ELEMENTS</li>
                <li>HTML IFRAMES</li>
                <li>HTML LAYOUT</li>
                <li>HTML5 SEMANTIC TAGS</li>
                <li>HTML ENTITIES</li>
                <li>HTML EMOJIS</li>
                <li>HTML FORMS</li>
              </ul>
            </div>
          </Modal>

          <div>
            <Modal
              open={open2}
              onClose={onCloseModalCSS}
              center
              classNames={{ modal: "customModal" }}
            >
              <div className="modalHeader">
                <h2>CSS Topics</h2>
              </div>
              <div className="modalContent">
                <ul className="topicList">
                  <li>CSS INTRODUCTIONS</li> <li>CSS SYNTAX</li>
                  <li>CSS BOX MODEL</li> <li>CSS COMMENTS</li>
                  <li>CSS BORDERS</li> <li>CSS MARGINS PADDINGS</li>
                  <li>CSS OUTLINE</li> <li>CSS BORDERS</li> <li>CSS FONTS</li>
                  <li>CSS ICONS</li> <li>CSS LINKS</li> <li>CSS LISTS</li>
                  <li>CSS TABLES</li> <li>CSS DISPLAY PROPERTY</li>
                  <li>CSS POSITIONS</li> <li>CSS Z-INDEX</li>
                  <li> CSS OVERFLOW</li> <li> CSS FLOAT</li> <li> CSS ALIGN</li>
                  <li> CSS COMBINATORS</li> <li> CSS PSEOUDO CLASSES</li>
                  <li> CSS PSEOUDO ELEMENTS</li> <li> CSS OPACITY</li>
                  <li> CSS ATTRIBUTE SELECTORS</li> <li> CSS UNITS</li>
                  <li> CSS SPECIFICITY, !IMPORTANT</li>
                  <li> CSS TEXT FORMATTING</li> <li> CSS BACKGROUNDS</li>
                  <li> CSS LISTS</li> <li> CSS FLEXBOX</li>
                  <li> CSS GRID(BASICS)</li> <li> CSS RESPONSIVE</li>
                </ul>
              </div>
            </Modal>
          </div>

          <div>
            <Modal
              open={open3}
              onClose={onCloseModalJS}
              center
              classNames={{ modal: "customModal" }}
            >
              <div className="modalHeader">
                <h2>JAVASCRIPT Topics</h2>
              </div>
              <div className="modalContent">
                <ul className="topicList">
                  <li>
                    <br /> INTODUCTION <br /> HOW TO ADD JS IN HTML <br /> JS
                    SYNTAX <br /> JS COMMENTS <br /> JS VERSIONS
                  </li>

                  <li>
                    <br /> VARIABLES <br /> DATATYPES <br /> GLOBAL & LOCAL
                    VARIABLES <br /> LET, CONST & VAR
                  </li>
                  <li>
                    <br /> OPREATORS PRECEDENCE <br /> ARTHMETIC OPRETORS <br />{" "}
                    ASSIGNMENT OPRATORS <br /> COMPARISON OPRATORS <br />{" "}
                    LOGICAL OPRATORS <br /> BITWISE OPRATORS <br /> TERNARY
                    OPRATORS <br /> COMMA OPRATORS <br /> UNARY OPRATORS <br />{" "}
                    RELATIONAL OPRATORS <br /> STRING OPRATORS
                  </li>
                  <li>
                    <br /> FOR LOOP <br /> WHILE LOOP <br /> DO-WHILE LOOP{" "}
                    <br /> FOR EACH LOOP
                  </li>
                  <li>
                    <br /> JS OBJECTS <br /> JS JSON OBJECTS <br /> JS REFRENCE
                    OBJECTS
                  </li>
                  <li>
                    <br /> WHAT IS FUNCTIONS <br /> TYPES OF FUNCTIONS <br />{" "}
                    DIFFRENCE BTW METHODS & FUNCTIONS
                  </li>
                  <li>
                    <br /> ARRAYS <br /> ARRAYS METHODS
                  </li>
                  <li>
                    {" "}
                    <br /> JS STRINGS <br /> STRINGMETHODS
                  </li>
                  <li>
                    {" "}
                    <br /> HOISTING <br /> ARROW FUNCTION <br /> PROMISES
                  </li>
                </ul>
              </div>
            </Modal>
          </div>

          <div>
            <Modal
              open={open4}
              onClose={onCloseModaLREACT}
              center
              classNames={{ modal: "customModal" }}
            >
              <div className="modalHeader">
                <h2>REACTJS Topics</h2>
              </div>
              <div className="modalContent">
                <ul className="topicList">
                  <li>
                    <br /> INRODUCTIONS
                    <br /> ENVIROMENT SETUP
                  </li>

                  <li>
                    <br /> INRODUCTION JSX
                    <br /> BABEL INTRODUCTION
                    <br /> VIRTUAL DOM
                    <br /> REAL DOM
                    <br /> REACT FORMS
                  </li>
                  <li>
                    <br /> CLASS COM VS FUNCT COM
                    <br /> REACT LIFECYCLE
                  </li>
                  <li>
                    <br /> STATES
                    <br /> PROPS
                  </li>
                  <li>
                    <br /> WHAT IS HOOKS
                    <br /> TYPES OF HOOKS
                    <br /> REACTJS FRAGMENTS
                  </li>
                  <li>REACT ROUTER DOM</li>
                  <li>
                    <br /> TODO APP
                    <br /> QUIZ APP
                  </li>
                  <li>
                    <br /> DEPOYMENT USING NETLIFY
                    <br /> HOW TO MAKE A BUILD
                  </li>
                  <li></li>
                </ul>
              </div>
            </Modal>
          </div>
        </div>
      </section>

      <section className="sixthSection">
        <div className="Apps">
          <header className="animated-header">
            <h1 className="animated-title">Meet Our MENTORS</h1>
            <p className="animated-subtitle">
              Learn from the best educators in the industry! Inspiring Minds,
              Shaping Futures
            </p>
          </header>
          <section className="teachers-section">
            {teachers.map((teacher, index) => (
              <div className="teacher-card" key={index}>
                <img
                  src={teacher.image}
                  alt={`${teacher.name}`}
                  className="teacher-image"
                />
                <h2 className="teacher-name">{teacher.name}</h2>
                <p className="teacher-subject">{teacher.subject}</p>
              </div>
            ))}
          </section>
        </div>
      </section>

      <section className="seventhSection">
        <div className="containerss">
          <motion.div
            className="offer-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <motion.p
              className="value-text"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              Total Market Value: <span>20,000/-</span>
            </motion.p>
            <motion.p
              className="normal-price-text"
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              Our Regular Price: <span>5,000/-</span>
            </motion.p>
            <div className="today-price">
              <motion.p
                className="old-price"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Not ₹4,000
              </motion.p>
              <motion.p
                className="new-price"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Only ₹999! but for a Limited first 15 Students...
              </motion.p>
            </div>

            <motion.p
              className="limited-offer"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              There is a special & limited offer for first 15 STudents. Save a
              large amount today on joining the course.
            </motion.p>
            <motion.p
              className="hurry-text"
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              Hurry up! Offer expiring soon in
            </motion.p>
            <CountdownTimer />
            <motion.div
              className="note"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <p className="note-text">
                This Course Contains Everything to Make You A Successful Dev.
                Don't Miss This Great Opportunity to Make Automatic Income For
                Your Rest of Life.
              </p>
            </motion.div>
            <motion.button
              className="button"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <form action="" id="razorpay-containers"></form>
            </motion.button>
          </motion.div>
        </div>
      </section>

      <section className="ninethSection">
        <div className="testimonial-container">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <p className="quote">"{testimonial.quote}"</p>
              <p className="author">- {testimonial.author}</p>
              <p className="role">{testimonial.role}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="eightSection">
        <header className="App-header">
          <h1>Frequently Asked Question</h1>
          <Accordion items={items} />
        </header>
      </section>

      <footer
        className="bg-body-tertiary text-center"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <MDBContainer className="p-4 pb-0">
          <section className="mb-4" style={{ margin: "0 auto" }}>
            {/* Twitter */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://x.com/Console2Success?t=w8Ajav2UlNO4PMoPSVpGuw&s=08"
              role="button"
              style={{ backgroundColor: "#55acee" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="twitter" />
            </MDBBtn>

            {/* Instagram */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://www.instagram.com/console2success?igsh=eTNkaDd6dHJkdTAw"
              role="button"
              style={{ backgroundColor: "#ac2bac" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="instagram" />
            </MDBBtn>

            {/* Linkedin */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://www.linkedin.com/company/console2success/"
              role="button"
              style={{ backgroundColor: "#0082ca" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="linkedin-in" />
            </MDBBtn>

            {/* Github */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://youtube.com/@console2success?si=Gz4VqzI5keA-Q9ac"
              role="button"
              style={{ backgroundColor: "red" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="youtube" />
            </MDBBtn>
          </section>
        </MDBContainer>

        <div
          className="bg-body-tertiary text-center"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          <strong>
            For any support, please mail to console2success@gmail.com. Our team
            will get back to you within 24 hours.
          </strong>
        </div>
        <div
          className="text-center p-3"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 Console2Success.All Rights are Reserved{" "}
          <NavLink style={{ marginLeft: "25px" }} to="/privacy-policy">
            Privacy Policy
          </NavLink>
          <NavLink style={{ marginLeft: "25px" }} to="/terms-conditions">
            Terms and Conditions
          </NavLink>
          <NavLink style={{ marginLeft: "25px" }} to="/refund-policy">
            Refund/Cancellation
          </NavLink>
        </div>
        {/* Copyright */}
      </footer>
    </>
  );
};

export default Home;
